import React from 'react'
import ReactModal from 'react-modal'
import styled from 'styled-components'

import icClose from '../../static/icons/ic-close.png'

ReactModal.setAppElement('#root')

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: '1px solid #DDDDDD',
    borderRadius: '10px',
    backgroundColor: '#FFFFFF',
    boxShadow: ' 0 6px 40px 0 rgba(0,0,0,0.1)',
    padding: '30px 20px',
    position: 'absolute',
    outline: 'none',
    boxSizing: 'border-box',
    maxHeight: 'calc(100vh - 20px)',
    width: '620px',
    overflowY: 'auto'
  },
  overlay: {
    zIndex: 1900
  }
}

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .modal-header {
    width: 100%;
    display: flex;

    &--title {
      color: #555555;
      width: 100%;
      font-family: 'Open Sans';
      font-size: 18px;
      font-weight: bold;
      line-height: 24px;
      display: flex;
      text-align: center;
      text-align: center;
      justify-content: center;
      margin-top: 15px;
      margin-bottom: 40px;
      @media screen and (min-width: 300px) and (max-width: 620px) {
        font-size: 16px;
        margin-top: 35px;
      }
    }
    &--close {
      border-radius: 50%;
      background: linear-gradient(270deg, #ff9700 0%, #ff7400 100%);
      height: 24px;
      width: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      border: none;
      cursor: pointer;
      outline: none;
      > img {
        width: 10px;
        display: block;
      }
    }
  }
  .modal-content {
    width: 100%;
    @media scre display: flex;
    flex-direction: column;
  }
`

const Modal = props => {
  const { title, children, active, onCloseModal, classCSS } = props
  const handleCloseModal = () => {
    onCloseModal()
  }
  const afterOpenModal = () => { }
  return (
    <div style={{ height: '100%' }}>
      <ReactModal
        isOpen={active}
        onAfterOpen={afterOpenModal}
        onRequestClose={handleCloseModal}
        style={customStyles}
        contentLabel="Example Modal"
        className="bounceInDown modal-responsive-custom"
      // closeTimeoutMS={200}
      >
        <ModalContent>
          <div className="modal-header">
            <div className="modal-header--title">{title}</div>
            <button
              onClick={handleCloseModal}
              type="button"
              className={classCSS}
            >
              <img src={icClose} alt="" loading="lazy" />
            </button>
          </div>
          <div className="modal-content">{children}</div>
        </ModalContent>
      </ReactModal>
    </div>
  )
}

export default Modal
