/* eslint-disable */
import React, { Fragment, useState, useRef } from 'react'
import Input from '../../form/input'
import Label from '../../form/label'
import Group from '../../form/group'
import SelectInputPhone from '../../form/input-phone-number'
import Select from '../../form/select'

import styled from 'styled-components'
import ReCAPTCHA from "react-google-recaptcha";

const StepTwoStyle = styled.div`
  width: 352px;
  @media screen and (min-width: 300px) and (max-width: 620px) {
    width: 100%;
  }
  display: flex;
  margin: 0 auto;
  @media screen and (min-width: 300px) and (max-width: 620px) {
    margin: -20px auto;
  }
  flex-direction: column;
  height: 100%;
  .step-two-title {
    display: flex;
    color: #555555;
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    margin-bottom: 12px;
  }
  .choose-box {
    border: 1px solid #eeeeee;
    background-color: #ffffff;

    margin-bottom: 10px;
    flex-direction: row;
    padding: 8px;
    align-items: center;
    display: flex;
    cursor: pointer;
    transition: all 300ms linear;
    &:hover {
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
    }
    .checkmark {
      height: 24px;
      width: 24px;
      border-radius: 50%;
      display: inline-flex;
      margin-right: 10px;
      transition: all 300ms linear;
      position: relative;
      transform: rotate(45deg);
      background-color: #eeeeee;
      border: 3px solid #ffffff;

      &--circle {
        position: absolute;
        width: 22px;
        height: 22px;
        border-radius: 11px;
        left: 0;
        top: 0;
      }
      &--stem {
        position: absolute;
        width: 2px;
        height: 9px;
        background-color: #fff;
        left: 12px;
        top: 6px;
      }
      &--kick {
        position: absolute;
        width: 6px;
        height: 2px;
        background-color: #fff;
        left: 8px;
        top: 15px;
      }
    }
    &.active {
      .checkmark {
        background-color: #4866c9;
      }
    }
  }

  .step-two-title {
    display: flex;
    color: #555555;
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    margin-bottom: 3px;
  }
  .step-two-sub-title {
    display: flex;
    color: #888888;
    font-family: 'Open Sans';
    font-size: 12px;
    line-height: 17px;
    margin-bottom: 16px;
  }
  .upload-image {
    display: flex;
    border: 1px solid #eeeeee;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
    padding: 16px;
    align-items: center;
    margin-bottom: 15px;
    > .image {
      display: inline-flex;
      height: 62px;
      width: 93px;
      border-radius: 5px;
      background-color: #eeeeee;
      > img {
        width: 100%;
        display: block;
        height: 100%;
      }
    }
    > .button {
      display: inline-flex;
      flex-direction: column;
      margin-left: 10px;
      > label {
        color: #555555;
        font-family: 'Open Sans';
        font-size: 12px;
        line-height: 17px;
      }
      > button {
        border: 1px solid #4866c9;
        border-radius: 5px;
        color: #4866c9;
        width: 100px;
        padding: 10px 0;
        margin-top: 8px;
        font-size: 14px;
        font-weight: bold;
      }
      input {
        display: none;
      }
    }
  }
  .step-two-bottom {
    .right {
      p {
        font-family: 'Open Sans';
        font-size: 14px;
        font-weight: 600;
        line-height: 19px;
      }
      a {
        color: #4866c9;
        text-decoration: none;
      }
    }
  }
  .check-box {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .check-box input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    border-radius: 5px;
    background-color: #ddd;
  }

  .check-box:hover input ~ .checkmark {
    background-color: #ccc;
  }

  .check-box input:checked ~ .checkmark {
    background-color: #4866c9;
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  .check-box input:checked ~ .checkmark:after {
    display: block;
  }

  .check-box .checkmark:after {
    left: 7px;
    top: 4px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .modal-footer {
    display: block !important;
  }
`

const onChange = (value) => {
  return
}

const StepTwo = props => {
  const inputFile = useRef(null)
  const [imageView, setImageView] = useState(false)
  const [imageName, setNameImage] = useState(false)

  const handleClickUpload = () => inputFile.current.click()

  const handleChangeInput = event => {
    if (event.target.files && event.target.files[0]) {
      setNameImage(event.target.files[0].name)
      let reader = new FileReader()
      reader.onload = e => {
        setImageView(e.target.result)
      }
      reader.readAsDataURL(event.target.files[0])
    }
  }

  const { step } = props
  const [data, setData] = useState([
    {
      value: 1,
      text: 'Browsing di internet'
    },
    {
      value: 2,
      text: 'Social media'
    },
    {
      value: 3,
      text: 'Browsing di internet'
    },
    {
      value: 4,
      text: 'Staff promosi'
    }
  ])

  const handleSubmitButton = () => { }
  if (step !== 2) {
    return false
  }

  return (
    <Fragment>
      <StepTwoStyle>
        <div className="modal-form">
          <form action="" id="register-vendor-two" style={{ width: '100%', display: 'block' }}>
            <Group>
              <Label
                withStep={
                  'Masukkan nama lengkap anda dengan benar, untuk keperluan proses verifikasi'
                }
                htmlFor="vendorName"
              >
                Nama Perusahaan
              </Label>
              <Input
                placeholder="eg. Ahmad Widodo"
                name="vendorName"
                type="text"
              ></Input>
            </Group>
            <Group>
              <Label htmlFor="vendorName">Legalitas Perusahaan</Label>
              <Select
                placeholder="Pilih jenis legalitas perusahaan"
                name="vendorName"
                type="text"
                data={[{ value: 'legalitas', text: 'legalitas' }]}
              ></Select>
            </Group>
            <Group>
              <Label
                withStep={
                  'Masukkan nomor telepon anda dengan benar, untuk keperluan proses verifikasi'
                }
                htmlFor="handphone"
              >
                No. Telepon Perusahaan
              </Label>
              <SelectInputPhone placeholderInput="812 90 1999234 90"></SelectInputPhone>
            </Group>

            <div className="step-two-title">Upload Legalitas Perusahaan</div>
            <div className="step-two-sub-title">
              Masukkan identitas surat izin usaha dan tanda daftar perusahaan
              format pdf atau png
            </div>
            <div className="upload-image">
              <div className="image">
                {imageView ? <img src={imageView} alt="" loading="lazy" /> : false}
              </div>
              <div className="button">
                <label htmlFor="">
                  {imageName || 'tidak ada file terpilih'}
                </label>
                <input
                  name="image"
                  onChange={handleChangeInput}
                  ref={inputFile}
                  type="file"
                />
                <button onClick={handleClickUpload} type="button">
                  Upload File
                </button>
              </div>
            </div>

            <div className="step-two-title">
              Upload Profil Perusahaan (optional)
            </div>
            <div className="step-two-sub-title">
              Masukkan profil usaha (bila ada) dengan format pdf atau png
            </div>
            <div className="upload-image">
              <div className="image">
                {imageView ? <img src={imageView} alt="" loading="lazy" /> : false}
              </div>
              <div className="button">
                <label htmlFor="">
                  {imageName || 'tidak ada file terpilih'}
                </label>
                <input
                  name="image"
                  onChange={handleChangeInput}
                  ref={inputFile}
                  type="file"
                />
                <button onClick={handleClickUpload} type="button">
                  Upload File
                </button>
              </div>
            </div>
          </form>
        </div>

        <div className="modal-footer">
          <ReCAPTCHA
            sitekey="6LfFmb8UAAAAALlXXVTp6yV9iCuTuiGvtvVi8liX"
            onChange={onChange}
          />
          <br />
          <button
            style={{ width: '100%' }}
            onClick={handleSubmitButton}
            type="button"
            className="smaller-hover"
          >
            Daftar
          </button>
        </div>
      </StepTwoStyle>
    </Fragment>
  )
}

export default StepTwo
