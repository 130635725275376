import React, { Fragment, useState, useRef } from 'react'
import styled from 'styled-components'
import ReCAPTCHA from 'react-google-recaptcha'
import swal from 'sweetalert'
import imageCompression from 'browser-image-compression'

const StepTwoStyle = styled.div`
  width: 352px;
  @media screen and (min-width: 300px) and (max-width: 620px) {
    width: 100%;
  }
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  height: 100%;
  .step-two-title {
    display: flex;
    color: #555555;
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    margin-bottom: 3px;
  }
  .step-two-sub-title {
    display: flex;
    color: #888888;
    font-family: 'Open Sans';
    font-size: 12px;
    line-height: 17px;
    margin-bottom: 16px;
  }
  .upload-image {
    display: flex;
    border: 1px solid #eeeeee;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
    padding: 16px;
    align-items: center;
    > .image {
      display: inline-flex;
      height: 62px;
      width: 93px;
      border-radius: 5px;
      background-color: #eeeeee;
      > img {
        width: 100%;
        display: block;
        height: 100%;
      }
    }
    > .button {
      display: inline-flex;
      flex-direction: column;
      margin-left: 10px;
      > label {
        color: #555555;
        font-family: 'Open Sans';
        font-size: 12px;
        line-height: 17px;
      }
      > button {
        border: 1px solid #4866c9;
        border-radius: 5px;
        color: #4866c9;
        width: 100px;
        padding: 10px 0;
        margin-top: 8px;
        font-size: 14px;
        font-weight: bold;
      }
      input {
        display: none;
      }
    }
  }
  .step-two-bottom {
    .right {
      p {
        font-family: 'Open Sans';
        font-size: 14px;
        font-weight: 600;
        line-height: 19px;
      }
      a {
        color: #4866c9;
        text-decoration: none;
      }
    }
  }
  .check-box {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .check-box input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    border-radius: 5px;
    background-color: #ddd;
  }

  .check-box:hover input ~ .checkmark {
    background-color: #ccc;
  }

  .check-box input:checked ~ .checkmark {
    background-color: #4866c9;
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  .check-box input:checked ~ .checkmark:after {
    display: block;
  }

  .check-box .checkmark:after {
    left: 7px;
    top: 4px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .modal-footer {
    display: block !important;
  }
`
const StepFour = props => {
  const inputFile = useRef(null)
  // eslint-disable-next-line
  const [imageView, setImageView] = useState(false)
  // const [imageName, setNameImage] = useState(false)
  const { step, onChange, registerNow, upload, regisData } = props
  const [disabled, setDisabled] = useState(true)

  if (step !== 4) {
    return false
  }

  const handleAgreement = () => {
    setDisabled(!disabled)
  }

  const handleClickUpload = () => inputFile.current.click()

  const handleChangeInput = async event => {
    const imageFile = event.target.files[0]
    if (imageFile.size <= 1000000) {
      await uploadToServer(imageFile)
    } else {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1600,
        useWebWorker: true
      }
      try {
        const compressedFile = await imageCompression(imageFile, options)
        await uploadToServer(compressedFile)
      } catch (error) {
        return
      }
    }
  }

  function uploadToServer(event) {
    let dataPic = new FormData()
    dataPic.append('image', event)
    upload(dataPic)
  }

  const checkField = () => {
    const enter = regisData
    if (enter.image === '') {
      return swal('Warning', 'Image can not be empty', 'warning')
    } else {
      registerNow()
    }
  }

  return (
    <Fragment>
      <StepTwoStyle>
        <div className="step-two-title">Upload foto KTP anda</div>
        <div className="step-two-sub-title">Foto KTP anda dengan jelas</div>
        <div className="upload-image">
          <div className="image">
            {props.regisData.image ? (
              <img
                src={
                  'https://storage.googleapis.com/halojasa-content-production/' +
                  props.regisData.image
                }
                alt=""
                loading="lazy"
              />
            ) : (
              false
            )}
          </div>
          <div className="button">
            <input
              onChange={handleChangeInput}
              ref={inputFile}
              name="image"
              type="file"
            />
            <button onClick={handleClickUpload} type="button">
              Upload File
            </button>
          </div>
        </div>
        <div className="step-two-bottom">
          <div className="left"></div>
          <div className="right">
            <label className="check-box">
              <p>
                Dengan saya klik “Daftar”, saya bersedia untuk mengikuti aturan
                dan ketentuan.
              </p>
              <p>
                Saya menyetujui <a href="/">Syarat Umum</a>,{' '}
                <a href="/">Ketentuan vendor</a>, dan{' '}
                <a href="/"> Kebijakan Privasi</a>
              </p>
              <input type="checkbox" onClick={handleAgreement} />
              <span className="checkmark"></span>
            </label>
          </div>
        </div>
      </StepTwoStyle>
      <div className="modal-footer" style={{ display: 'block' }}>
        <ReCAPTCHA
          sitekey="6LfFmb8UAAAAALlXXVTp6yV9iCuTuiGvtvVi8liX"
          onChange={onChange}
        />
        <br />
        <button
          style={{ width: '100%' }}
          onClick={checkField}
          className="input-daftar"
          disabled={disabled}
        >
          Daftar
        </button>
      </div>
    </Fragment>
  )
}

export default StepFour
