/* eslint-disable */

import React, { useState, useCallback, useMemo, useEffect } from 'react'
import HeaderStyle from './index.style'
import LogoWhite from '../../../static/icons/ic-logo-white.png'
import { IoIosClose } from 'react-icons/io'
import { MdMenu, MdExpandMore } from 'react-icons/md'
import Register from '../register'
import RegisterVendor from '../register-vendor'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const HeaderResponsive = props => {
  let { t, i18n } = useTranslation()
  const [lang, setLang] = useState(true)
  const [isShowRegister, setShowRegister] = useState(false)
  const [isService, setService] = useState(false)
  const [isShowPopUpMobile, setShowPopUpMobile] = useState(false)
  const [isShowPopUpRegister, setShowPopUpRegister] = useState(true)
  const [isShowRegisterVendor, setShowRegisterVendor] = useState(false)
  const [isActiveAbout, setActiveAbout] = useState(false)

  useEffect(() => {
    const urlParams = new URLSearchParams(props.location.search)

    if (urlParams.get('register-vendor') == true) {
      handleShowRegister()
    }
  }, [isShowRegisterVendor, isShowPopUpMobile])

  const handlingRegister = () => {
    if (window.location.pathname.includes('register-vendor')) {
      return (
        <Register
          handleOnCloseModal={handleCloseModal}
          active={isShowPopUpRegister}
        ></Register>
      )
    } else {
      return (
        <Register
          handleOnCloseModal={handleCloseModal}
          active={isShowPopUpMobile}
        ></Register>
      )
    }
  }

  useEffect(() => {
    i18n.changeLanguage(lang ? 'idn' : 'en')
    // if (window.location.pathname.includes('register-vendor')) {
    //   setShowRegisterVendor(true)
    // }
  }, [lang])

  const handleChangeLang = () => {
    setLang(!lang)
  }

  const [activeResponsiveHeader, setActiveResponsiveHeader] = useState(false)

  const toggleMenuResponsive = () => {
    setActiveResponsiveHeader(!activeResponsiveHeader)
  }

  const [activeCollapseBantuan, setActiveCollapseBantuan] = useState(false)

  const toggleCollapseBantuan = () => {
    setActiveCollapseBantuan(!activeCollapseBantuan)
  }

  const [activeCollapseLayanan, setActiveCollapseLayanan] = useState(false)

  const toggleCollapseLayanan = () => {
    setActiveCollapseLayanan(!activeCollapseLayanan)
  }

  const [activeCollapseTentang, setActiveCollapseTentang] = useState(false)

  const toggleCollapseTentang = () => {
    setActiveCollapseTentang(!activeCollapseTentang)
  }

  const handleShowRegister = useCallback(() => {
    setShowPopUpMobile(true)
    setShowRegister(false)
    // window.location.href = "https://halojasa.co.id/vendor/register";
  })
  const handleShowRegisterVendor = useCallback(() => {
    onShowRegisterVendor()
    setShowRegister(false)
    // window.location.href = "https://halojasa.co.id/vendor/register";
  })

  const handleCloseModal = useCallback(() => {
    document.body.classList.remove('disabled-scroll')
    setShowPopUpMobile(false)
    setShowRegisterVendor(false)
    setShowPopUpRegister(false)
  }, [])

  const onShowRegisterVendor = useCallback(() => {
    if (!isShowRegisterVendor) {
      document.body.classList.add('disabled-scroll')
    } else {
      document.body.classList.remove('disabled-scroll')
    }
    setShowRegisterVendor(true)
  }, [])

  const getBackground = useMemo(() => {
    const { location } = props
    const color = {
      '/': 'rgba(255, 123, 0)',
      'job-list': 'rgba(255, 123, 0)',
      overview: 'rgba(255, 123, 0)',
      about: 'rgba(255, 123, 0)',
      faq: 'rgba(255, 123, 0)',
      about: 'rgba(255, 123, 0)',
      'help-center': 'rgba(255, 123, 0)',
      'privacy-policy': 'rgba(255, 123, 0)',
      'term-and-condition': 'rgba(255, 123, 0)',
      'privacy-policy': 'rgba(255, 123, 0)',
      'halo-fix': 'rgba(3, 50, 96)',
      'halo-auto': 'rgba(85, 70, 195)',
      'halo-massage': 'rgba(165, 58, 20)',
      'halo-clean': 'rgba(42, 94, 132)'
    }
    return location.pathname === '/'
      ? color['/']
      : color[location.pathname.match(/\/([^\/]+)\/?$/)[1]]
  })

  if (window.location.href.indexOf('webview') > -1) return null

  return (
    <HeaderStyle id="header" background={getBackground}>
      <div className="header-responsive-head">
        <MdMenu color={'#ffffff'} size={30} onClick={toggleMenuResponsive} />
        <img className="halo-jasa-icon-responsive" src={LogoWhite} alt="" loading="lazy" />
      </div>

      <div
        className={`header-reponsive-custom ${activeResponsiveHeader ? 'active-header-responsive' : ''
          } `}
      >
        <button
          type="button"
          className="close-responsive-header"
          onClick={toggleMenuResponsive}
        >
          <IoIosClose color={'#ffffff'} size={40} />
        </button>

        <div className="header-responsive-content-wrapper">
          <Link onClick={toggleMenuResponsive} to="/">
            <img className="halo-jasa-icon-responsive" src={LogoWhite} alt="" loading="lazy" />
          </Link>

          <ul className="main-responsive-header-menu">
            <li
              className={`${activeCollapseTentang ? 'active-expand-responsive-menu' : ''
                }`}
              onClick={toggleCollapseTentang}
            >
              {t('headerNavbar.perusahaanKami')}
              <MdExpandMore
                className="arrow-menu"
                color={'#ffffff'}
                size={25}
              />
              <ul>
                <li>
                  <Link
                    className="about-mobile"
                    onClick={toggleMenuResponsive}
                    to="/bantuan/tentang-kami"
                  >
                    {t('headerNavbar.tentangKami')}
                  </Link>
                </li>
                <li>
                  <a href="/jasa/">Cabang</a>
                </li>
                <li>
                  <Link
                    className="overview-mobile"
                    onClick={toggleMenuResponsive}
                    to="/overview"
                  >
                    Overview
                  </Link>
                </li>
                {/* <li>
                  <a href="/team">Team</a>
                </li> */}
                <li>
                  <Link
                    className="career-mobile"
                    onClick={toggleMenuResponsive}
                    to="/karir/all/"
                  >
                    {t('headerNavbar.karir')}
                  </Link>
                </li>
                <li>
                  <a
                    className="career-mobile"
                    href="https://halojasa.com/blog-vendor/"
                  >
                    Pelatihan
                  </a>
                </li>
                <li>
                  <a href="https://halojasa.com/jasa/franchise-spa/">Mitra Usaha</a>
                </li>
              </ul>
            </li>

            <li
              className={`${activeCollapseLayanan ? 'active-expand-responsive-menu' : ''
                }`}
              onClick={toggleCollapseLayanan}
            >
              {t('headerNavbar.layananKami')}
              <MdExpandMore
                className="arrow-menu"
                color={'#ffffff'}
                size={25}
              />
              <ul>
                <li>
                  <Link
                    className="massage-mobile"
                    onClick={toggleMenuResponsive}
                    to="/reflexology-massage-pijat-panggilan/"
                  >
                    Halo Massage
                  </Link>
                </li>
                <li>
                  <Link
                    className="auto-mobile"
                    onClick={toggleMenuResponsive}
                    to="/salon-mobil-pangilan/"
                  >
                    Halo Auto
                  </Link>
                </li>
                <li>
                  <Link
                    className="fix-mobile"
                    onClick={toggleMenuResponsive}
                    to="/service-ac-jakarta/"
                  >
                    Halo Fix
                  </Link>
                </li>
                <li>
                  <Link
                    className="cleaning-mobile"
                    onClick={toggleMenuResponsive}
                    to="/jasa-cleaning-service/"
                  >
                    Halo Clean
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <a className="blog-mobile" href="https://halojasa.com/blog/">
                Blog
              </a>
            </li>

            <li
              className={`${activeCollapseBantuan ? 'active-expand-responsive-menu' : ''
                }`}
              onClick={toggleCollapseBantuan}
            >
              {t('headerNavbar.pusatBantuan')}
              <MdExpandMore
                className="arrow-menu"
                color={'#ffffff'}
                size={25}
              />
              <ul>
                <li>
                  <Link
                    className="help-mobile"
                    onClick={toggleMenuResponsive}
                    to="/bantuan/pusat-bantuan/"
                  >
                    Pusat Bantuan
                  </Link>
                </li>
                <li>
                  <Link
                    className="privacy-mobile"
                    onClick={toggleMenuResponsive}
                    to="/bantuan/privacy/"
                  >
                    Kebijakan Privasi
                  </Link>
                </li>
                <li>
                  <Link
                    className="term-mobile"
                    onClick={toggleMenuResponsive}
                    to="/bantuan/syarat-umum/"
                  >
                    Syarat Umum
                  </Link>
                </li>
                <li>
                  <Link
                    className="payment-mobile"
                    onClick={toggleMenuResponsive}
                    to="/bantuan/cara-pembayaran/"
                  >
                    Pembayaran
                  </Link>
                </li>
                <li>
                  <Link
                    className="faq-mobile"
                    onClick={toggleMenuResponsive}
                    to="/bantuan/faq/"
                  >
                    FAQ
                  </Link>
                </li>
              </ul>
            </li>
          </ul>

          <div className="header-responsive-header-wrapper">
            <button
              className="white-button vendor-mobile"
              onClick={handleShowRegisterVendor}
            >
              Register Vendor
            </button>

            <button
              className="white-button individual-mobile"
              onClick={handleShowRegister}
            >
              Register Individual
            </button>

            <button className="orange-button" onClick={handleChangeLang}>
              {lang ? 'Indonesia' : 'English'}
            </button>
          </div>
        </div>
      </div>
      <Register
        handleOnCloseModal={handleCloseModal}
        active={isShowPopUpMobile}
      ></Register>
      <RegisterVendor
        handleOnCloseModal={handleCloseModal}
        active={isShowRegisterVendor}
      ></RegisterVendor>
      {handlingRegister()}
    </HeaderStyle>
  )
}

export default HeaderResponsive
